import logo from "../../src/VidaViajes-Imagotipo.png";
import { ReactComponent as Whats } from './../images/svg/whats.svg';
import { ReactComponent as Calendar } from './../images/svg/calendar.svg';

export default function NavBarAlterno() {
  const tidy = "https://tidycal.com/vidaviajesqro";
  const whatsURL = "https://bit.ly/2P9rvre";

  return (
    <section className="bg-[url('/public/assets/big.jpg')] bg-cover bg-center" id="citas">
      <div className="grid bg-black bg-opacity-40 flex flex-col items-center justify-center text-center flex-row justify-center justify-start">
        <div className="p-20 md:p-40">
          <div className="flex justify-center items-center">
            <img src={logo} alt="Vida Viajes Logo" className="h-60 max-w-full" />
          </div>
          <div className="p-10 bg-black bg-opacity-50">
          <h3 className="mb-4 aqua-vida text-center font-bold tracking-tight leading-none text-white text-4xl">Agencia de Viajes</h3>
          <p className="mb-4 font-normal text-white text-2xl">Tu agencia de viajes en Querétaro donde encontrarás la mejor opción.</p>
          <button onClick={() => window.open(tidy, '_blank')} className="blue-viajes-bg hover:bg-blue-700 text-white font-bold py-2 px-3 m-2 rounded-full">
            <p className="p-2 flex items-center justify-center md:justify-start">
              <Calendar className="mr-2 w-5 h-5"/>
              Agenda Cita
            </p>
          </button>
          <button onClick={() => window.open(whatsURL, '_blank')} className="blue-viajes-bg hover:bg-blue-700 text-white font-bold py-2 px-3 m-2 rounded-full">
            <p className="p-2 flex items-center justify-center md:justify-start">
              <Whats className="mr-2 w-5 h-5" />
              Solicita Cotizacion
            </p>
          </button>
          <p className="m-4 text-white text-lg">Horario: Lunes a Jueves de 11 am a 6 pm y Viernes de 10 am a 2 pm.</p>
          <p className="m-4 text-white text-sm">Agenda tu cita para una mejor atención.</p>

          </div>
        
          
          
        </div>
      </div>
    </section> 
  )
}