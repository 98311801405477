import React from 'react';


const Service = ({ icon: Icon, title, description }) => {
  return (
    <div className="flex flex-col items-center justify-center m-2">
      <Icon className="w-8 h-8 md:w-16 md:h-16 mb-2 object-cover rounded-full text-white" />
      <h3 className="text-lg font-semibold mb-1 text-white">{title}</h3>
      <p className="text-lg font-semibold mb-1 text-white">{description}</p>
    </div>
  );
};

export default Service;