import React, { useState, useEffect } from 'react';


const Carousel = ({ images }) => {
  const [intervalId, setIntervalId] = useState(null);
  useEffect(() => {
      const id = setInterval(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
      }, 3000);
      setIntervalId(id);
      return () => clearInterval(id);
    }, [images.length]);
const [currentImage, setCurrentImage] = useState(0);

const handlePrevClick = () => {
  setCurrentImage((prevImage) => (prevImage - 1 + images.length) % images.length);
};

const handleNextClick = () => {
  setCurrentImage((prevImage) => (prevImage + 1) % images.length);
};

return (
  <div className="w-128 h-128 relative">
    <button
      className="absolute top-1/2 -translate-y-1/2 left-0 bg-gray-300 p-2 rounded-full"
      onClick={handlePrevClick}
    >
      &lt;
    </button>
    <img
      className="object-cover w-full h-full"
      src={'./assets/' + images[currentImage]}
      alt={`Slide ${currentImage + 1}`}
    />
    <button
      className="absolute top-1/2 -translate-y-1/2 right-0 bg-gray-300 p-2 rounded-full"
      onClick={handleNextClick}
    >
      &gt;
    </button>
  </div>
);
};

export default Carousel;