import { ReactComponent as Plane } from './../images/svg/plane.svg'
import { ReactComponent as Hotel } from './../images/svg/hotel.svg'
import { ReactComponent as Moon } from './../images/svg/moon.svg'
import { ReactComponent as Bus } from './../images/svg/bus.svg'
import { ReactComponent as World } from './../images/svg/world.svg'
import { ReactComponent as Tour } from './../images/svg/tour.svg'
import { ReactComponent as Beach } from './../images/svg/beach.svg'
import Carousel from './Carousel'
import Service from './Service'

const images = ["circuitos.png", "asistencia.png", "hotel.png", "fit.png", "paquetes.png", "playa.png", "tours.png"];

export default function JumbotronQuienes() {
  
  return (
    <section className="bg-[url('/public/assets/beach.jpg')] bg-cover bg-center" id="quienessomos">
      <br />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 bg-black bg-opacity-60 items-center justify-center text-center p-5">
        <div className="p-10 md:p-20">
          <h1 className="font-bold tracking-tight leading-none text-4xl sm:text-4xl md:text-5xl aqua-vida  bg-clip-text text-transparent linear-gradient">¿Quiénes Somos?</h1>
          <p className="m-4 font-normal text-white text-base sm:text-xl md:text-xl">Vida Viajes es una agencia minorista de viajes ubicada en Corregidora, Querétaro, desde marzo de 2018. Nos dedicamos a hacer realidad tus planes de viaje. Nos especializamos en viajes individuales a nivel nacional y circuitos para destinos internacionales.</p>
          <h1 className="mb-4 font-bold tracking-tight leading-none text-4xl sm:text-4xl md:text-5xl aqua-vida bg-clip-text text-transparent linear-gradient">Servicios</h1>

          <div className="flex flex-wrap justify-center">
            <Service icon={Plane} title="Vuelos"/>
            <Service icon={Hotel} title="Hospedaje"/>
            <Service icon={World} title="Circuitos"/>
            <Service icon={Tour} title="Tours"/>
            <Service icon={Bus} title="Traslados"/>
            <Service icon={Beach} title="Paquetes"/>
            <Service icon={Moon} title="Luna de Miel"/>
          </div>
        </div>
        
        <div className="flex justify-center items-center p-10"> 
          <Carousel images={images} /> 
        </div>
      </div>
      <br id="contacto" />
    </section> 
  )
}