import Service from './Service'
import { ReactComponent as Office } from './../images/svg/office.svg'
import { ReactComponent as Phone } from './../images/svg/phone.svg'
import { ReactComponent as Mail } from './../images/svg/mail.svg'

const GoogleMapsIframe = () => {
  return (

    <section className="bg-[url('/public/assets/fuji.jpg')] bg-cover bg-center">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 bg-black bg-opacity-60 items-center justify-center text-center p-5">
        <div className="p-5">
          <h1 className="mb-4 font-bold tracking-tight leading-none text-4xl sm:text-4xl md:text-5xl aqua-vida bg-clip-text text-transparent linear-gradient">Contáctanos</h1>

          <div className="flex flex-wrap justify-center">
            <div className="flex flex-col items-center justify-center mx-8">
              <Mail className="w-8 h-8 md:w-16 md:h-16 mb-2 object-cover rounded-full text-white" />
              <h3 className="text-lg font-semibold mb-1 text-white">Correo</h3>
              <p className="text-lg font-semibold mb-1 text-white"><a href="mailto:vidaviajesqro@gmail.com">vidaviajesqro@gmail.com</a></p>
            </div>
            
            
            <div className="flex flex-col items-center justify-center mx-8">
              <Phone className="w-8 h-8 md:w-16 md:h-16 mb-2 object-cover rounded-full text-white" />
              <h3 className="text-lg font-semibold mb-1 text-white">Telefonos</h3>
              <p className="text-lg font-semibold mb-1 text-white"> <a href="tel:+4427767234">Celular: 442 776 7234</a> <br/><a href="tel:+4422416722">Oficina:  442 241 6722</a></p>
            </div>

            <div className="flex flex-col items-center justify-center mx-8">
              <Office className="w-8 h-8 md:w-16 md:h-16 mb-2 object-cover rounded-full text-white" />
              <h3 className="text-lg font-semibold mb-1 text-white">Oficina</h3>
              <p className="text-lg font-semibold mb-1 text-white">Monterrey 80-B  <br /> San Jose De Los Olvera <br /> Corregidora, Queretaro</p>
            </div>
          </div>
          
        </div>
        
        <div className="flex justify-center items-center p-10"> 
          <div className="relative w-full h-96">
            <iframe className="absolute top-0 left-0 w-full h-full"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3735.8415903504174!2d-100.41189850370483!3d20.55366135412136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d345121dd731bd%3A0x76209cc40fc7956b!2sVida%20Viajes!5e0!3m2!1ses-419!2smx!4v1710998435324!5m2!1ses-419!2smx" 
                      loading="lazy" title='vida-viajes-location'>
            </iframe>
          </div>
        </div>
      </div>
    </section> 
    
  );
};

export default GoogleMapsIframe;