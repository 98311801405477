import Isotipo from "../../src/images/isotipo.png";

const Footer = () => {
  return (
    <footer
      className="bg-neutral-100 text-center text-neutral-600 dark:bg-neutral-600 dark:text-neutral-200 lg:text-left">
      <div className="mx-6 py-5 text-center md:text-left">
        <div className="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          <div className="">
            <h6
              className="mb-4 flex items-center justify-center font-semibold md:justify-start">
              <img className="h-10 w-auto" src={Isotipo} alt="Vida Viajes" />
              Vida Viajes
            </h6>
          </div>
          <div className="">
            <h6
              className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
              Enlaces
            </h6>
            <p className="mb-4">
              <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1e56Hmp_10V7XDjsYekrDdc1gd80hJ6HR/view?usp=sharing" className="text-neutral-600 dark:text-neutral-200"
              >Aviso De Privacidad</a>
            </p>
          </div>
        </div>
      </div>

      <div className="bg-neutral-200 p-6 text-center dark:bg-neutral-700">
        <span>© 2024 Copyright: Vida Viajes</span>
      </div>
    </footer>
  );
};

export default Footer;