import { useEffect } from 'react';
import Jumbotron from './Components/JumbotronAlterno';
import JumbotronQuienes from './Components/JumbotronQuienes';
import GoogleMapsIframe from './Components/GoogleMapsIframe';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import './App.css';

function App() {
  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  return (
    <div className="App">
      <Navbar />
      <Jumbotron/>
      <JumbotronQuienes/>
      <GoogleMapsIframe/>
      <Footer/>
    </div>
  );
}

export default App;
