import React, { useRef, useState, useEffect } from 'react';
import { ReactComponent as Insta } from './../images/svg/insta.svg';
import { ReactComponent as Fb } from './../images/svg/fb.svg';
import logo from "../../src/images/isotipo.png";


export default function Example() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const scrollToComponent = useRef(null);
  const fb = "https://www.facebook.com/VidaViajesQro";
  const instaURL = "https://www.instagram.com/vidaviajesqro";

  const handleScroll = () => {
    scrollToComponent.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 's') {
        handleScroll();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
 
  return (
    <nav className="bg-current fixed w-full z-20 top-0 start-0">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
      
            <button type="button" onClick={() => setNavbarOpen(!navbarOpen)} className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>
              
            
              <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            
              <svg onClick={() => window.open(fb, '_blank')} className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
      
            <a type="button" href={fb} target='_blank' rel="noopener noreferrer" className="mx-2 bg-transparent p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-offset-gray-800">       
              <Fb />
            </a>
            <a type="button" href={instaURL} target='_blank' rel="noopener noreferrer" className="bg-transparent p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-offset-gray-800">
              <Insta />
            </a>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch">
            <div className="flex flex-shrink-0 items-center">
              <img className="h-10 w-auto" src={logo} alt="Vida Viajes" />
              <p className='text-white'>Vida Viajes</p>
            </div>
            <div className="hidden sm:ml-6 sm:block items-center justify-center">
              <div className="flex space-x-2 justify-center pt-1">
              
                <a href="#citas" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Citas</a>
                <a href="#contacto" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Contacto</a>
                <a href="https://viaje.ly/vida-viajes" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Revista Digital</a>
                <a href="#quienessomos" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Quienes Somos?</a>
                <a type="button" href={fb} target='_blank' rel="noopener noreferrer" className="bg-transparent p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-offset-gray-800"><Fb/></a>
                <a type="button" href={instaURL} target='_blank' rel="noopener noreferrer" className="bg-transparent p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-offset-gray-800"><Insta/></a>
              </div>
            </div>
          </div>
          
        </div>
      </div>

    {navbarOpen && ( <div className="sm:hidden" id="mobile-menu">
        <div className="space-y-1 px-2 pb-3 pt-2">
          <a onClick={() => setNavbarOpen(!navbarOpen)} href="#citas" className="bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium" aria-current="page">Citas</a>
          <a onClick={() => setNavbarOpen(!navbarOpen)} href="#contacto" className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Contacto</a>
          <a onClick={() => setNavbarOpen(!navbarOpen)} href="https://viaje.ly/vida-viajes" rel="noopener noreferrer" target="_blank" className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Revista Digital</a>
          <a onClick={() => setNavbarOpen(!navbarOpen)} href="#quienessomos" className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Quiénes Somos?</a>
        </div>
      </div>)}
    </nav> 
  )
}